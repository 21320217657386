import {makeStyles} from '@material-ui/core/styles';

export default makeStyles(() =>({
   
    root: {
        minWidth: '500px',
        // background: "linear-gradient(90deg, rgba(249,203,150,255) 0%, rgba(140,140,140,255))",
        background: "white",
        height: '400px',
        width: '500px'


    },
    stepper: {
        maxWidth: '100%',
        backgroundColor: 'clear'
    },
    media: {
        height: 500,
        paddingTop: '56.25%',
        position: 'relative',
    },
    cardActions: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    cardContent: {
        display: 'flex',
        justifyContent: 'space-between',
        
        
    },
    "@media screen and (max-width: 450px)": {       
        root:{
          minWidth: "350px",
        }
    },
    "@media screen and (min-width: 2500px)": {       
        root:{
          minWidth: "700px",
          height: "500px"
        }
    }
}
));