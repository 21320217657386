import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

function Footer() {
  return (
    <div className='footer-container'>    
      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>About Us</h2>
            <HashLink to='/#aboutUs' > About </HashLink>            
          </div>
          <div className='footer-link-items'>
            <h2>Contact Us</h2>
            <HashLink to='/#contactUs' > Contact </HashLink>          
          </div>
        </div>
        <div className='footer-link-wrapper'>        
          <div className='footer-link-items'>
            <h2>Social Media</h2>
            <Link onClick = {() => {window.location.href = 'https://www.instagram.com/kades.kloset/' }} to='/'>Instagram</Link>            
          </div>
        </div>
      </div>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <Link to='/' className='social-logo' onClick = {() => window.scrollTo(0, 0)}>
              Kade's Kloset
            </Link>
          </div>
          <small className='website-rights'>Maad's Web Consulting © 2021</small>
          <div className='social-icons'>           
            <Link
              className='social-icon-link instagram'
              to='/'
              target='_blank'
              aria-label='Instagram'
              onClick = {() => {window.location.href = ''; }}
            >
              <i className='fab fa-instagram' />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
