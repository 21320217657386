import React, {useState, useEffect} from 'react';
import {commerce} from './lib/commerce';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import Ecommerce from './Ecommerce';
import Footer from './components/Footer';
import Cart from './components/Cart/Cart';
import Checkout from './components/CheckoutForm/Checkout/Checkout';
import { Button } from '@material-ui/core';

function App() {


  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState(null);
  const [cart, setCart] = useState({});
  const [category, setCategory] = useState("All Products");
  const [selectedImg, setSelectedImg] = useState(null);
  const [sizes, setSizes] = useState([]);
  const [size, setSize] = useState("");

  // const [description, setDescription] = useState("");
  // const [url, setUrl] = useState("");
  // const [order, setOrder] = useState({});
  // const [errorMessage, setErrorMessage]= useState('');


  
  // const handleDescription = (product) => {
  //     const des = product.description.replace(/(<([^>]+)>)/gi, "");
  //     if(des.includes ("http")) {
  //     const tokens = des.split(" ");
  //     const urlToken = tokens[tokens.length - 1]
  //     setUrl(urlToken);
  //     const newDescription = des.replace(tokens[tokens.length - 1], "");
  //     setDescription(newDescription);
  //     } else {
  //       setDescription(des);
  //       setUrl("");
  //     }

  // }
  
  const closeModal = () => {
    setSelectedImg(null);
  }
  const fetchProducts = async () => {
      const { data } = await commerce.products.list();
      setProducts(data);
  }
  const fetchCart = async () => {
     setCart(await commerce.cart.retrieve());
  }
  
  //will only add if within max quantity
  const handleAddToCart = async (product, quantity, op, variantID) => {
      var items = await commerce.cart.contents();
      var i = 0;
      const variantData = {[variantID] : op,};
      if(items.length < 1 || product.inventory.available !== 1 ){
        const {cart} = await commerce.cart.add(product.id, quantity, variantData);
        setCart(cart);
      }
      else {
        items = await commerce.cart.contents();
        for(i = 0; i < items.length; i++) {
          if(items[i].product_id === product.id) {
            console.log("already added to cart an only one in stock")
            return;
            
          } 
        }
        const {cart} = await commerce.cart.add(product.id, quantity, variantData);
        setCart(cart);
      } 
      
  }
  // will only update if within max quantity 
  const handleUpdateCartQty = async (item, quantity) => {
    var i = 0;
    var maxQuant = 0;
    for(i =0; i < products.length; i++) {
      if(products[i].name === item.name) {
        maxQuant = products[i].inventory.available;
        break;
      }
    }
    if(quantity <= maxQuant){
    const { cart } = await commerce.cart.update(item.id, {quantity});
    setCart(cart);
    } else {
      return;
    } 
  }
  const handleRemoveFromCart = async (productId) => {
    const {cart} = await commerce.cart.remove(productId);
    setCart(cart);
  }
  const handleEmptyCart = async () => {
    const {cart} = await commerce.cart.empty();
    window.scrollTo(0, 0);
    setCart(cart);
  }
  const refreshCart = async () => {
    const newCart = await commerce.cart.refresh();
    setCart(newCart);
  }

  // const handleCaptureCheckout = async (checkoutTokenId, newOrder) => {
  //   try {
  //     const incomingOrder = await commerce.checkout.capture(checkoutTokenId, newOrder);

  //     setOrder(incomingOrder);
  //     refreshCart();

  //   } catch (error) {
  //     setErrorMessage(error.data.error.message);

  //   }  
    
  // }

  useEffect(() => {
      fetchProducts();
      fetchCart();
      window.scrollTo(0, 0);
  }, []);
  return (
    <div className = "mainDiv" style = {{scrollBehavior:"smooth",overflowY:"scroll", overflowX:"hidden", position:"fixed",width: "100%",background: "url('/images/kadeAbout.jpeg') center center/cover no-repeat"}}>
      <Router  onUpdate={() => window.scrollTo(0, 0)}>
        {!selectedImg && <Navbar cart = {cart} />}
        {/* <Navbar cart = {cart} /> */}
        <Switch>
          <Route  path='/' exact  ><Home/> </Route>
          <Route exact  path = "/shop">        
                <Ecommerce 
                    products = {products} 
                    product = {product}
                    setProduct = {setProduct}
                    setCategory = {setCategory} 
                    category = {category} 
                    cart = {cart}
                    handleAddToCart = {handleAddToCart}
                    selectedImg = {selectedImg}
                    setSelectedImg = {setSelectedImg}
                    closeModal = {closeModal}
                    size = {size}
                    setSize = {setSize}
                    sizes = {sizes}
                    setSizes = {setSizes}
                    //handleDescription = {handleDescription}
                    //url = {url}
                   // description = {description}
                    >
                    
                    
                </Ecommerce>
          </Route>          
          <Route exact path = "/cart">
                     <Cart 
                        cart = {cart}
                        handleUpdateCartQty = {handleUpdateCartQty}
                        handleRemoveFromCart = {handleRemoveFromCart}
                        handleEmptyCart = {handleEmptyCart}
                    />
                     </Route>
          <Route exact path = "/checkout">
                          <Checkout 
                          cart = {cart} 
                       //   order = {order}
                          // onCaptureCheckout = {handleCaptureCheckout}
                        //  error = {errorMessage}
                          refreshCart = {refreshCart}
                          ></Checkout>
                     </Route>
          <Route exact path = "/thankyou">
            <div className = "thank-you" >
              <center><h1>Thank you for your purchase!</h1>
              <h3>You will receive your receipt via email as soon as your order is confirmed.</h3><br/>
        <br/>
              <img style = {{height: "50%", width: "50%"}} src = "/images/kade.jpg" alt = "logo"/> <br/> <br/>
              <Button component = {Link} to = "/" style={{backgroundColor: "lightgrey"}} >Home</Button> <Button component= {Link} to ="/shop" style={{backgroundColor: "lightgray"}} >Shop</Button></center>
            </div>
          </Route>
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
