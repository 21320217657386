import { Typography } from '@material-ui/core';
import React from 'react';
import './Cards.css';

function Cards() {
  return (
    <div >
    <div  className='cards'>
      <br id = "aboutUs"/>
      <br/>
      <br/>
      <br />
      <h1 style={{color: "white", fontSize: "50px"}} >About Us</h1>
      <h1 style={{color:"white"}}>______________________</h1>
      <center>
        <br/>
        <br/>
        <Typography style={{color:"white"}} variant="h5">
         Founded in 2020, but have been<br/> passionate 
         about high end fashion<br/> for well over a decade. 
         Here at<br/> Kade's Kloset we focus on providing<br/> 100% 
         authentic streetwear as well<br/> as many different 
         designer brands. <br/>We constantly restock inventory 
         and<br/> check our competitors prices to<br/> ensure our 
         customers are getting<br/> the absoulute juug!
         
      </Typography>
        <br/>
        <br/>
        <img style = {{height: "50%", width: "50%"}} src = "/images/kade.jpg" alt = "logo"/>
        <div></div>
        <br id = "contactUs"/>
        <br/>
        <br/>
        
        <h1 style={{color: "white",fontSize: "50px"}} >Contact Us</h1>
        <h1 style={{color: "white"}}>______________________</h1>
        <br/>
        
        <Typography style ={{color: "white"}} variant="h5">
          Please feel free to shoot us<br/> 
          an email for any questions or<br/> 
          concerns, our team will reach<br/> 
          back ASAP!</Typography>
        
        <br/>
        
        <br/>

        <p style ={{color:"white", fontSize: "30px"}}>Email: kadekreiser2000@gmail.com</p>
        <br/>
      </center>
      <br/>
     
    </div>
    </div>
  );
}

export default Cards;
