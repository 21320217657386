import React from 'react';
import {Typography, Button, Card, CardActions, CardContent, CardMedia} from '@material-ui/core'
import useStyles from './styles';
const CartItem = ({item, handleRemoveFromCart, handleUpdateCartQty}) => {
   const classes = useStyles();
    return (
        <Card className = {classes.root} style = {{width :"315px",  position: "relative"}}>
            <CardMedia image = {item.media.source} alt={item.name} className={classes.media} />
            <CardContent className= {classes.cardContent}>
                <Typography variant= "h5">{item.name}</Typography>
                <Typography variant= "h6">{item.line_total.formatted_with_symbol}</Typography>
                <br/>
                <Typography style ={{position: 'relative', top: '40px', right: '60px'}} variant= "h6">Size: {item.selected_options[0].option_name}</Typography>
            </CardContent>
            <CardActions className= {classes.CardActions}>
                
                <div style = {{position: 'relative', top: '20px'}} className = {classes.buttons}>
                    <Button type = "button" size = "small" onClick = {() => handleUpdateCartQty(item, item.quantity - 1)} >-</Button>
                    <Typography>{item.quantity}</Typography>
                    <Button type = "button" size = "small" onClick = {() => handleUpdateCartQty(item, item.quantity + 1)}>+</Button>
                </div>
                <Button style ={{position: 'relative', top: '20px'}} variant = "contained" type = "button" color = "secondary" onClick = {() => handleRemoveFromCart(item.id)} >Remove</Button>
            </CardActions>
        </Card>
    )
}

export default CartItem
