import React, {useState, useEffect} from 'react';
import {Paper, Stepper, Step, StepLabel, Typography, CircularProgress, Button, CssBaseline} from '@material-ui/core';
import useStyles from './checkoutStyle';
import AddressForm from '../AddressForm';
import PaymentForm from '../PaymentForm';
import { commerce } from '../../../lib/commerce';
import { Link } from 'react-router-dom';
const steps = ['Review', 'Checkout'];

const Checkout = ({cart, order, onCaptureCheckout, error, refreshCart}) => {
    const [activeStep, setActiveStep] = useState(1);
    const [checkoutToken, setCheckoutToken] = useState(null);
    const [shipOps, setShipOps] = useState({"Standard Shipping - ($15.00)" : 15, "Premium Shipping - ($20.00)" : 20});
    const [shipOp, setShipOp] = useState("Standard Shipping - ($15.00)");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [address1, setAddress1] = useState("");
    const [zip, setZip] = useState("");
    const [city, setCity] = useState("");
    const [email, setEmail] = useState("");
    const [country, setCountry] = useState('');
    const [sub, setSub] = useState('');
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [addressError, setAddressError] = useState(false);
    const [zipError, setZipError] = useState(false);
    const [cityError, setCityError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [checkoutStr, setCheckoutStr] = useState("");
    const [success, setSuccess ] = useState(false);
    
    
    const classes = useStyles();
    const generateToken = async () => {

        commerce.checkout.generateToken(cart.id, { type: 'cart' }).then((checkout) => {setCheckoutToken(checkout)});
    }

    useEffect(() => {
        
        generateToken();
    },[cart] );

    function validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    const nextStep = () => {setActiveStep((prevActiveStep) => prevActiveStep + 1); window.scrollTo(0, 0);}

    const backStep = () =>{ setActiveStep((prevActiveStep) => prevActiveStep - 1); window.scrollTo(0, 0);}

    const validateCheckout = (data) => {
      if(data.firstName === '') {
        setFirstNameError(true);
      } 
      if(data.lastName === '') {
        setLastNameError(true);
      }
      if(data.city === '') {
        setCityError(true);
      }
      if(data.address1 === '') {
        setAddressError(true);
      }
      if(validateEmail(data.email) === false) {
        setEmailError(true);
      }
      if(data.zip === '') {
        setZipError(true);
      }
      if(data.firstName !== '') {
        setFirstNameError(false);
      } 
      if(data.lastName !== '') {
        setLastNameError(false);
      }
      if(data.city !== '') {
        setCityError(false);
      }
      if(data.address1 !== '') {
        setAddressError(false);
      }
      if(validateEmail(data.email)) {
        setEmailError(false);
      }
      if(data.zip !== '') {
        setZipError(false);
      }
    }

    const next = (data, op, checkoutToken, country, state) => {
       
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setCity(data.city);
        setZip(data.zip);
        setAddress1(data.address1);
        setEmail(data.email);
        setShipOp(op);
        setCountry(country);
        setSub(state);
        validateCheckout(data);
        if(data.firstName !== '' && data.lastName !== '' && data.city !== '' && data.zip !== '' && data.address1 !== '' && validateEmail(data.email) === true) {
          
              nextStep();
              window.scrollTo(0, 0);
         
        }
        checkoutString(checkoutToken);
    }
    const checkoutString = (checkoutToken) => {
        var str = ""
        checkoutToken && checkoutToken.live.line_items.map((product) => (
          str += product.name + " Size: " + product.selected_options[0].option_name + " Quantity: " + product.quantity  + ",  "
        ))
        var ans = str.slice(0,-3);
        console.log(ans);
        setCheckoutStr(ans);
    }
   
    let Confirmation = () => (success ? (
        <>
          <div>
          <Typography variant = "h5">Thank you for your purchase {firstName}! We will get you your drip shipped ASAP, please check your email for a receipt.</Typography>
           <br />
          <Button onClick = {refreshCart} component={Link} variant="outlined" type="button" to="/">Back to home</Button> <Button onClick = {refreshCart} component={Link} variant="outlined" type="button" to="/shop">Continue shopping</Button>
          </div>
        </>
      ) : (
        <div className={classes.spinner}>
         
          <CircularProgress />
        </div>
      ));
    
    const Form = () => activeStep === 0
        ? <AddressForm 
               firstNameError = {firstNameError}
               lastNameError = {lastNameError}
               cityError = {cityError}
               addressError = {addressError}
               emailError = {emailError}
               zipError = {zipError}
               firstName = {firstName}
               city = {city}
               lastName = {lastName}
               zip = {zip}
               address1 = {address1}
               email = {email}
               checkoutToken = {checkoutToken}
               next = {next}
               shipOp = {shipOp}
               shipOps = {shipOps} 
               
           />
        : <PaymentForm cart = {cart} success = {success} setSuccess = {setSuccess} country = {country} sub = {sub} shippingCity = {city} address1 = {address1} shippingZip = {zip} refreshCart = {refreshCart}  checkoutToken = {checkoutToken} backStep = {backStep} nextStep = {nextStep} shipOp = {shipOp} shipOps = {shipOps} firstName = {firstName}  checkoutStr = {checkoutStr}  email = {email} />
    return (
        <>
        <div style= {{ padding: "0rem 0 42rem 0"}}>
            <CssBaseline />
            <div  className = {classes.toolbar} />      
            <main className = {classes.layout}>
                <Paper className = {classes.paper}>
                    <Typography variant = "h4" align = "center">Checkout</Typography>
                    <Stepper activeStep = {activeStep} className={classes.stepper}>
                        {steps.map((step) => (
                            <Step key = {step}>
                                <StepLabel>{step}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    {checkoutToken && <PaymentForm checkoutString = {checkoutString} cart = {cart} success = {success} setSuccess = {setSuccess} country = {country} sub = {sub} shippingCity = {city} address1 = {address1} shippingZip = {zip} refreshCart = {refreshCart}  checkoutToken = {checkoutToken} backStep = {backStep} nextStep = {nextStep} shipOp = {shipOp} shipOps = {shipOps} setShipOp = {setShipOp} firstName = {firstName}  checkoutStr = {checkoutStr}  email = {email} />}
                </Paper>
            </main>
            </div>
        </>
    )
}

export default Checkout
