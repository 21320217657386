import React from 'react';
import Grid from '@material-ui/core/Grid';
import Product from './Product/Product';
import useStyles from './styles';
//import classes from '*.module.css';
// const products = [
//     { id: 1, name: 'Lily', description: 'Milena\'s fav.', price: '$69', image: 'https://www.almanac.com/sites/default/files/image_nodes/oriental-lily.jpg'},
//     { id: 2, name: 'Gardenia', description: 'Milena\'s other fav.', price: '$69', image: 'https://cdn.shopify.com/s/files/1/0062/8532/8445/products/Double_Blooming_Gardenia_3_BB_1024x1024.jpg?v=1569613051'},
//     { id: 3, name: 'Jasmine', description: 'Milena\'s other other fav.', price: '$69', image: 'https://s3.amazonaws.com/mygardenlife.com/plant-library/full/6259_10.jpg' },
//     { id: 4, name: 'Tuberose', description: 'Milena\'s other other other fav.', price: '$69', image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTVH0z2hJF1qFOLZtweJXOGBLZVC960kgjaiw&usqp=CAU'},
    
// ];

const Products = ({ size, setSize, sizes, setSizes, products, setSelectedImg, onAddToCart, selectedImg, product, setProduct}) => {
    const classes = useStyles();
    
    // products.map((product) => (

    // ));

    

    return (
        <main style = {{background: "none"}} className = {classes.content}>  
           <br/>
            <Grid container justify="center" spacing = {4}>
                {products.map((product) => (
                   
                    <Grid item key= {product.id} >
                        <Product size = {size} sizes = {sizes} setSize = {setSize} setSizes = {setSizes} setProduct = {setProduct} product = {product} selectedImg = {selectedImg} setSelectedImg={setSelectedImg} onAddToCart = {onAddToCart} ></Product>
                       
                    </Grid>
                ))}
            </Grid>
        </main>
    )
}
export default Products;