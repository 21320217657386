import React from 'react'
import {Container, Typography, Button, Grid} from '@material-ui/core'
import useStyles from './styles';
import {Link} from 'react-router-dom';
import CartItem from './CartItem/CartItem';

const Cart = ({ cart, handleUpdateCartQty, handleRemoveFromCart, handleEmptyCart }) => {
    const classes = useStyles();
    const EmptyCard = () => (
        <Typography style= {{ padding: "2rem 0 88rem 0"}} variant = "h5">You have no items in your shopping cart <Link className = {classes.link}to ={"/shop"}>start adding some</Link>!</Typography>
        );
    
        const FilledCard = () => (
        
        <main style = {{background: "transparent"}} className = {classes.content}>
        <div className = {classes.content} style= {{ padding: "0rem 0 41rem 0"}} >
            <Grid container justify = "center" spacing={4}>
                {cart.line_items.map((item) => (
                    <Grid  item key = {item.id}>
                        <CartItem 
                            item = {item} 
                            handleUpdateCartQty = {handleUpdateCartQty}
                            handleRemoveFromCart = {handleRemoveFromCart}
                        />
                    </Grid>
                ))}
            </Grid>
             <div className ={classes.cardDetails}>
                <h2 style={{color:'white'}}>Subtotal: {cart.subtotal.formatted_with_symbol}</h2>
                <div>
                    <Button className = {classes.emptyButton} size = "large" type = "button" variant = "contained" color = "secondary" onClick = {handleEmptyCart} >Empty Cart</Button>
                    <Button  onClick = {() => window.scrollTo(0, 0)} component = {Link} to = {"/checkout"}className = {classes.checkout} size = "large" type = "button" variant = "contained" color = "primary">Checkout</Button>

                </div>
            </div>
            </div>
        </main>
        );
    if(!cart.line_items) return 'Loading...';
    return (
        <Container>
            
            <h1 style={{color:"white"}} >Your Shopping Cart</h1>
            {!cart.line_items.length ? <EmptyCard/> : <FilledCard/>}
            <br/>
            <br/>
        </Container>
    )
}

export default Cart;
