import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  //background: theme.url('/images/kade.jpg'),
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: "white",
    padding: theme.spacing(3),
  },
  root: {
    flexGrow: 1,
  },
}));
