import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import {IconButton, Badge} from '@material-ui/core';
import {ShoppingCart} from '@material-ui/icons';

function Navbar({cart}) {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);

  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
          <Link to='/' className='navbar-logo' onClick={() => {closeMobileMenu();
                                                               window.scrollTo(0, 0);}}>
             Kade's Kloset
          </Link>
          <div className='menu-icon' onClick={handleClick}>
            {click ? <i className = 'fas fa-times'/> : <Badge badgeContent = {cart.total_items} color = "secondary">
              <i className = 'fas fa-bars' />
              </Badge>}         
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link to='/' className='nav-links' onClick={() => {closeMobileMenu();
                                                               window.scrollTo(0, 0);}}>
                Home
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/shop'
                className='nav-links'
                onClick={() => {closeMobileMenu();
                  window.scrollTo(0, 0);}}>
                Shop
              </Link>
            </li>
            <div>
                    <IconButton onClick={() => {closeMobileMenu(); window.scrollTo(0,0);}} component={Link} to={"/cart"} aria-label= "Show cart items" style ={{ color: "white", position: "relative", top: "20px"}}>
                        <Badge badgeContent= {cart.total_items} color = "secondary">
                            <ShoppingCart />
                        </Badge>
                    </IconButton>
            </div>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
