import React, {useState} from 'react';
import {Typography, Divider, InputLabel, Select, MenuItem} from '@material-ui/core';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import Review from './Review';
import Payment from './Payment';


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PaymentForm = ({checkoutString, cart, success, setSuccess, firstName, checkoutToken, backStep, nextStep, shipOp, setShipOp, shipOps, refreshCart, checkoutStr, email, country, sub, shippingCity, address1, shippingZip}) => {
    // const [shippingOption, setShippingOption] = useState(shipOp);
   const subtotal = checkoutToken.live.subtotal.raw;
   const tax = (subtotal * 0.0875)
   const shipping = shipOps[shipOp];
   const afterTax = subtotal + tax;
   const afterShipping = afterTax + shipping;
   const total = (afterShipping * 1.00)
   const totalInCents = total.toFixed(2) * 100;
  
   const options = Object.keys(shipOps);

    return (
        <>
            <Review checkoutString = {checkoutString} setShipOp = {setShipOp} shipOps = {shipOps} cart = {cart} checkoutToken = {checkoutToken} success = {success} setSuccess = {setSuccess} nextStep = {nextStep} shipOp = {shipOp} shippingCity = {shippingCity} shippingCountry = {country} shippingSubdivision = {sub} shippingZip = {shippingZip} address1 = {address1} checkoutStr = {checkoutStr} firstName = {firstName} refreshCart = {refreshCart} backStep = {backStep} totalInCents = {totalInCents} email = {email} total = {total} tax = {tax} shipping = {shipping}/>
            {/* <Divider/>
            <Typography variant = "h6" gutterBottom style= {{margin: '20px 0'}}>Shipping Options</Typography> */}

            {/* <InputLabel>Shipping Options</InputLabel>
                            <Select value = {shippingOption} fullWidth onChange = {(e) => setShippingOption(e.target.value)}>
                            {options.map((option) => (
                                         <MenuItem key = {option} value = {option}>
                                            {option}
                                        </MenuItem>
                                ))}                           
                            </Select> */}


            {/* <Elements stripe = {stripePromise} >
                <Payment checkoutString = {checkoutString} cart = {cart} checkoutToken = {checkoutToken} success = {success} setSuccess = {setSuccess} nextStep = {nextStep} shipOp = {shipOp} shippingCity = {shippingCity} shippingCountry = {country} shippingSubdivision = {sub} shippingZip = {shippingZip} address1 = {address1} checkoutStr = {checkoutStr} firstName = {firstName} refreshCart = {refreshCart} backStep = {backStep} totalInCents = {totalInCents} email = {email} total = {total.toFixed(2)} tax = {tax.toFixed(2)} />
            </Elements> */}

        </>
    )
}

export default PaymentForm
