import {makeStyles} from '@material-ui/core/styles';

export default makeStyles(() => ({
    root: {
        minWidth: '300px',
        position: 'relative',
        height: '520px',
        width: '500px',
        justifyContent: 'space-between'
    },
    media: {
        position: 'relative', 
        height: 260,        
    },
    cardContent: {
        display: 'flex',
        justifyContent: 'space-between',        
    },
    cartActions: {
        justifyContent: 'space-between',
    },
    buttons: {
        display: 'flex',
        alignItems: 'center',
    },
}));