import { Button, Typography } from "@material-ui/core"
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import axios from "axios"
import React from 'react'
import emailjs from 'emailjs-com';
import { Link } from "react-router-dom";



export default function Payment({shippingOption, checkoutString, cart, checkoutToken, success, setSuccess ,shipOp, backStep, email, total, refreshCart, firstName, checkoutStr, shippingCountry, shippingSubdivision, shippingCity, address1, shippingZip, nextStep, tax}) {
    const stripe = useStripe()
    const elements = useElements()
    var imgArray = new Array();
    imgArray[0] = new Image();
    imgArray[0].src = '/images/kade.jpg';
    var pic = imgArray[0].src;
    console.log("imageArray[0].src: ",imgArray[0].src);
    console.log("checkoutToken: ", checkoutToken);

    //var pic = url('/images/kade.jpg');

    var templateParams = {
        to_email: email,
        to_name: firstName,
        order_summary: checkoutStr,
        country: shippingCountry,
        state: shippingSubdivision,
        city: shippingCity,
        street_address: address1,
        zip: shippingZip,
        shippingOption: shipOp,
        tax: tax,
        total: total
    };


    const handleSubmit = async (e) => {
        e.preventDefault()
       // checkoutString()
        // const {error, paymentMethod} = await stripe.createPaymentMethod({
        //     type: "card",
        //     card: elements.getElement(CardElement)
        // })
       // nextStep();

    if(true) {
        try {
            //const {id} = paymentMethod
            const response = await axios.post("https://kloset-server.herokuapp.com/payment", {
                amount: 0,
                email,
                
                checkoutToken,
                shippingOption, 
                cart,
                pic,
                checkoutStr
                
            })

            if(response.data.success) {

                console.log("Successful payment");
                window.location.href = response.data.url;
                setSuccess(true);
                // emailjs.send('gmail', 'template_kawjiev', templateParams,
                // 'user_5n9lyg46quUPxt4A7tjUs')
                //     .then(function(response) {
                //     console.log('SUCCESS!', response.status, response.text);
                //     }, function(error) {
                //     console.log('FAILED...', error);
                //     });
                
               

            }

        } catch (error) {
            console.log("Error", error)
        }
    } 
    // else {
    //     console.log(error.message)
    // }
  refreshCart();
}

    return (
        <>
        {/* {!success ?  */}
        <form onSubmit={handleSubmit}>
                    {/* <CardElement /> */}
                    <br/><br/>
                        <div style = {{display : 'flex', justifyContent: 'space-between'}}>
            
                            <Button variant = "outlined" component = {Link} to = "/cart">Back</Button>
                            <Button onClick={refreshCart} type = "submit" variant = "contained"  color = "primary">
                                Pay
                            </Button>
                        </div>
        </form>
        {/* :
       <div>           
           <Typography variant = "h5">Thank you for your purchase {firstName}! We will get you your drip shipped ASAP, please check your email for a receipt.</Typography>
           <br />
          <Button onClick = {refreshCart} component={Link} variant="outlined" type="button" to="/">Back to home</Button> <Button onClick = {refreshCart} component={Link} variant="outlined" type="button" to="/shop">Continue shopping</Button>
       </div>  */}
        {/* }             */}
        </>
    )
}