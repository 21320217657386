import React from 'react';
import './components/Cards.css';
import Modal from './Modal';
import { Button, MenuItem, Menu} from '@material-ui/core';
import Products from './components/Products/Products';
const Ecommerce = ({ size, sizes, setSize, setSizes, closeModal, selectedImg, setSelectedImg, products, setCategory, category, handleAddToCart, product, setProduct}) => {
  
  // const useStyles = makeStyles((theme) => ({
  
  //   formControl: {
  //     minWidth: 120
  //   }
  // }));
  const [anchorEl, setAnchorEl] = React.useState(null);

//   const [activeStep, setActiveStep] = React.useState(0);
//   //const maxSteps = ;
//   const handleNext = () => {
//     if(activeStep === product.assets.length - 1) {
//       setActiveStep(0);
//     } else {
//       setActiveStep((prevActiveStep) => prevActiveStep + 1);
//     }
// };

// const handleBack = () => {
//     if(activeStep === 0) {
//       setActiveStep(product.assets.length - 1);
//     } else {
//     setActiveStep((prevActiveStep) => prevActiveStep - 1);
//     }
// };

  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
   const categoriess = new Set(); 
   var displayItems = [];
    for(var i = 0; i < products.length; i++) {
      
      for(var j = 0; j < products[i].categories.length; j++) {
        categoriess.add(products[i].categories[j].name);
        if(products[i].categories[j].name === category) {
          displayItems.push(products[i]);
          break;
        }
      }
    }
    
    return (
        <div style= {{padding: "3rem 0 40rem 0"}}>
         
          
          <center>
          <h1 style={{color:"white"}}>{category}</h1>
          <p style={{color:"white"}}>____________</p>
          <Button style ={{color: "white", fontSize: "18px"}} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        Choose Category
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        
      >
        {Array.from(categoriess).map((category, index) => (
                        <MenuItem onClick={() => {setCategory(category); handleClose(); }} key= {index}>{category}</MenuItem>
                    ))}
      </Menu> 
      
      </center>
      
          <Products 
          size = {size} sizes = {sizes} setSize = {setSize} setSizes = {setSizes} product = {product} setProduct = {setProduct} products = {displayItems} setSelectedImg={setSelectedImg} onAddToCart = {handleAddToCart} selectedImg = {selectedImg}/>  
          { selectedImg && (
        <Modal  size = {size} sizes = {sizes} setSize = {setSize} setSizes = {setSizes} onAddToCart = {handleAddToCart} closeModal = {closeModal} product = {product} selectedImg={selectedImg} setSelectedImg={setSelectedImg} />
      )}
      
      </div>
   
    )
}

export default Ecommerce;
