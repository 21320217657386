import React from 'react';
import {Typography, List, ListItem, ListItemText, Divider, InputLabel, Select, MenuItem } from '@material-ui/core';
import { useState } from 'react';
import {loadStripe} from '@stripe/stripe-js';
import Payment from './Payment';
import {Elements} from '@stripe/react-stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

//import { commerce } from '../../lib/commerce';
const Review = ({checkoutToken, shipping, shipOp, shipOps, setShipOp, total, tax, cart, success, setSuccess, nextStep, shippingCity, country, sub,backStep,firstName, address1, shippingZip, email, totalInCents, refreshCart, checkoutStr}) => {
   // commerce.checkout.getLive(checkoutToken).then((response) => console.log(response));
  // console.log(checkoutToken);
  // const subtotal = checkoutToken.live.subtotal.raw;
  // const shipping = shippingData.shippingCountry === "US" ? checkoutToken.live.shipping.available_options[0].price.raw : checkoutToken.live.shipping.available_options[1].price.raw;
  // const tax = 0;
  // const total = (subtotal + shipping).toFixed(2);
  const [shippingOption, setShippingOption] = useState(shipOp);
  const subtotal = checkoutToken.live.subtotal.raw;
   //const tax = (subtotal * 0.0875)
   const [shippin, setShippin] = useState(shipOps[shippingOption]);
    const afterTax = subtotal + tax;
//    const afterShipping = afterTax + shipping;
//    const total = (afterShipping * 1.00)
//    const totalInCents = total.toFixed(2) * 100;
  const [tot, setTot] = useState(total);
   const options = Object.keys(shipOps);
  

    return (
        <div>
            <Typography variant = "h6" gutterBottom>Order summary</Typography>
            <List disablePadding>
                {checkoutToken.live.line_items.map((product, index) => (
                    <ListItem style={{paddding: '10px 0'}} key = {index}>
                        <ListItemText primary = {product.name} secondary = {`Size: ${product.selected_options[0].option_name} \t Quantitiy: ${product.quantity}`}/> <br/>
                        {/* <ListItemText secondary = {`Quantitiy: ${product.quantity}`}/> */}
                        <Typography variant = "body2">{product.line_total.formatted_with_symbol}</Typography>
                    </ListItem>
                   
                ))}
                <ListItem style={{paddding: '10px 0'}} >
                        <ListItemText primary = "Shipping" secondary = {shipOp}/>
                        <Typography variant = "body2">${Number(shippin).toFixed(2)}</Typography>
                       
                    </ListItem>
                    <ListItem style={{paddding: '10px 0'}} >
                        <ListItemText primary = "Tax" secondary = "8.75%"/>
                        <Typography variant = "body2">${Number(tax).toFixed(2)}</Typography>
                       
                    </ListItem>
                <ListItem style={{padding: '10px 0' }}>
                    <ListItemText primary = "Total"/>
                    <Typography variant = "subtitle1" style = {{ fontWeight: 700}}>
                        ${Number(tot).toFixed(2)}
                    </Typography>
                </ListItem>
            </List>
            <Divider/>
            <Typography variant = "h6" gutterBottom style= {{margin: '20px 0'}}>Shipping Options</Typography>

            <InputLabel>Shipping Options</InputLabel>
                            <Select value = {shippingOption} fullWidth onChange = {(e) => {setShippingOption(e.target.value); setShippin(shipOps[e.target.value]); setTot(afterTax + shipOps[e.target.value])}}>
                            {options.map((option) => (
                                         <MenuItem key = {option} value = {option}>
                                            {option}
                                        </MenuItem>
                                ))}                           
                            </Select>


            <Elements stripe = {stripePromise} >
                <Payment shippingOption = {shippingOption} tot = {tot}  cart = {cart} checkoutToken = {checkoutToken} success = {success} setSuccess = {setSuccess} nextStep = {nextStep} shipOp = {shipOp} shippingCity = {shippingCity} shippingCountry = {country} shippingSubdivision = {sub} shippingZip = {shippingZip} address1 = {address1} checkoutStr = {checkoutStr} firstName = {firstName} refreshCart = {refreshCart} backStep = {backStep} totalInCents = {totalInCents} email = {email} total = {total.toFixed(2)} tax = {tax.toFixed(2)} />
            </Elements>
           
        </div>
    )
}

export default Review
