import React from 'react'
import { Card, CardMedia} from '@material-ui/core';
import useStyles from './styles';

const Product = ({ setSize, setSizes, product, setSelectedImg, setProduct}) => {
    
    const classes = useStyles();

    // const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    
    // const maxSteps = product.assets.length;

    // const handleNext = () => {
    //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // };

    // const handleBack = () => {
    //     setActiveStep((prevActiveStep) => prevActiveStep - 1);
    // };
   
    return (
         <div className = "product">
           
        <Card className={classes.root} style = {{width :"315px",  position: "relative"}}>
            <CardMedia 
          onClick={() =>{   setProduct(product); setSizes(product.variant_groups[0].options); setSize(product.variant_groups[0].options[0].name);  setSelectedImg(product.assets[activeStep].url);}} className = {classes.media} image={product.assets[activeStep].url} title = {product.name}/>  
            {/* <MobileStepper
            className={classes.stepper}
            steps={maxSteps}
            position="static"
            variant = {null}
            activeStep={activeStep}
            
            nextButton={
              <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
              </Button>
            }
            backButton={
              <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
              </Button>
            }
          /> */}
            {/* <CardContent>
                <div className = {classes.cardContent}>
                    <Typography variant = "h5" gutterBottom>
                        {product.name}
                    </Typography>
                    <Typography variant = "h5">
                        {product.price.formatted_with_symbol}
                    </Typography>

                </div>
                <Typography  variant = "body2" > 
               
                    {product.description.replace(/(<([^>]+)>)/gi, "")}
                </Typography>
            </CardContent> */}
            {/* <CardActions disableSpacing className= {classes.CardActions}>
                <IconButton aria-label= "Add to Cart" onClick = {() => onAddToCart(product, 1)}>
                    <AddShoppingCart/>
                </IconButton>
            </CardActions> */}
        </Card>
        <center>
        <h2 style={{color: "white"}} >{product.name}</h2>
        <h2 style={{color: "white"}}>{product.price.formatted_with_symbol}</h2></center>
        {/* { selectedImg && (
        <Modal selectedImg={selectedImg} setSelectedImg={setSelectedImg} />
      )} */}
        </div>
    )
}

export default Product;
