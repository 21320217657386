import React, {useState} from 'react';
import { motion } from 'framer-motion';
import './Modal.css';
import { Button, InputLabel, Select, MenuItem} from '@material-ui/core';

const Modal = ({ sizes, setSelectedImg, product, closeModal, onAddToCart }) => {
  console.log(product.assets);
  
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = product.assets.length;

  const [op, setOp] = useState(product.variant_groups[0].options[0].id);
  const variantID = product.variant_groups[0].id;
  const handleNext = () => {
      if(activeStep === maxSteps - 1) {
        setActiveStep(0);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
  };

  const handleBack = () => {
      if(activeStep === 0) {
        setActiveStep(maxSteps - 1);
      } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      }
  };
  const handleClick = (e) => {
    if(typeof(e.target.classList) !== "undefined") {
        if (e.target.classList.contains('backdrop')) {
          setSelectedImg(null);
        }
    }
  }
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }


  return (
    <motion.div className="backdrop" onClick ={handleClick}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <motion.div className="modal">
        {/* {product.assets.map((asset, index) =>(
          <img key={index} style={{height:"25%", width: "25%"}} src={asset.url} alt="error"></img>
        ))} */}
        <div style = {{position: 'relative', top: '-70px'}} >
            {/* <Button className = "closeBttn" onClick = {closeModal} >x</Button> */}
            <div className = "closeBttn">
            <i onClick ={closeModal} className = 'fas fa-times'/>
            </div>
            <h1>Product details:</h1>
            
            
            <div style = {{display: "flex", justifyContent: "center", alignItems: "center", position: "relative", top: "-50px"}}>
              <div className = "leftBttn" >
                <i style = {{color: 'white'}} onClick = {handleBack} className="fas fa-chevron-left"></i>   
              </div>  
              <img className = "modalpics" src = {product.assets[activeStep].url} alt = "" ></img> 
              <div className = "rightBttn" >
                <i style = {{color: 'white'}} onClick = {handleNext} className="fas fa-chevron-right"></i>
              </div>
          </div>
          <div style = {{position: "relative", top: "-100px"}}>
            <h3 className = "modalTitle" >{product.name}</h3>
            <h3 className = "price">{product.price.formatted_with_symbol}</h3>
            <InputLabel>Size</InputLabel>
            <Select value = {op} onChange ={(e) => setOp(e.target.value)}>
              {sizes.map((size, index) => (
                <MenuItem key = {index} value = {size.id} >{size.name}</MenuItem>
              ))}
            </Select>
            <br/>
            <br/>
            <h3 className = "description" > {product.description.replace(/(<([^>]+)>)/gi, "")}</h3>
            <br/>
          </div>
         
            {product.thank_you_url && <Button className = "stockxBttn" onClick = {() => openInNewTab(product.thank_you_url)} style={{ fontSize: '18px', color: 'white' ,textTransform: 'none'}} >Compare to StockX!</Button>}<br/><br/>
          <Button className = "backBttn" style={{backgroundColor: "lightgrey"}} onClick = {closeModal}>Back</Button> <Button className = "addBttn" style={{backgroundColor: "lightgray"}} onClick = {() => {onAddToCart(product, 1, op, variantID); closeModal();}}>Add to cart</Button>
       
      </div>
      </motion.div>
      {/* <motion.img src={selectedImg} alt="enlarged pic" 
        initial={{ y: "-100vh" }}
        animate={{ y: 0 }}
      /> */}
    </motion.div>
  )
}

export default Modal;